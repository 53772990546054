import React from 'react'

const Register = () => {
  return(
    <div>
      <h1>Register</h1>
    </div>
  )
}

export default Register;
